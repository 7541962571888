<template>
    <div>
        <div class="nk-block-head nk-block-head-sm">
            <div class="nk-block-between">
                <div class="nk-block-head-content">
                    <h4 class="title">My Dashboard</h4>
                </div><!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                        <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-more-v"></em></a>
                        <div class="toggle-expand-content" data-content="pageMenu">
                            <ul class="nk-block-tools g-3">
                                <li class="nk-block-tools-opt">
                                    <router-link :to="{ name: 'tickets' }" class="btn btn-success"><em class="icon ni ni-ticket"></em><span>Tickets</span></router-link></li>
                                <li>
                                    <div class="dropdown">
                                        <a href="#" class="dropdown-toggle btn btn-white btn-dim btn-outline-light" data-toggle="dropdown"><em class="d-none d-sm-inline icon ni ni-calender-date"></em><span><span class="d-none d-md-inline">Last</span> 30 Days</span><em class="dd-indc icon ni ni-chevron-right"></em></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <ul class="link-list-opt no-bdr">
                                                <li><a href="#"><span>Last 30 Days</span></a></li>
                                                <li><a href="#"><span>Last 6 Months</span></a></li>
                                                <li><a href="#"><span>Last 1 Years</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li class="nk-block-tools-opt"><a href="#" class="btn btn-primary"><em class="icon ni ni-reports"></em><span>Reports</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div><!-- .nk-block-head-content -->
            </div><!-- .nk-block-between -->
        </div>
        <div class="nk-block">
            <div class="col-xxl-12">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="row g-gs">
                            <div class="col-md-6 col-lg-12">
                                <holidayStats/>
                            </div>
                            <div class="col-md-6 col-lg-12">
                                <currentBooked/>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <myTasks/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import holidayStats from '@/components/holiday/dashboard/stats.vue'
import currentBooked from '@/components/holiday/dashboard/currentBooked.vue'
import myTasks from '@/components/tasks/myTasks.vue'

export default {
    components: { holidayStats,  currentBooked, myTasks },
    data () {
        return {
            
        }
    },
}
</script>

<style scoped>
.nk-block-head {
    padding: 20px;
}
</style>
